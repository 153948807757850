/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type MetricsService from 'tnt-ui/services/metrics';

export interface RailUpsellTooltipSignature {
  Element: HTMLDivElement;
  Args: {
    source?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class RailUpsellTooltipComponent extends Component<RailUpsellTooltipSignature> {
  @service declare metrics: MetricsService;

  @action
  onShow() {
    this.trackEvent('upsell_badge_shown');
  }

  @action
  getUpsellContent() {
    const upsellContentNode = document.getElementById('upsell');
    upsellContentNode?.classList.remove('tw-hidden');
    return upsellContentNode;
  }

  @action
  trackEvent(event = '') {
    const source = this.args.source;

    this.metrics.trackEvent({
      event,
      source,
    });
  }
}
