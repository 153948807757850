import { inject as service } from '@ember/service';
import V2ApiAdapter from './v2-api';

export default class ContainerViewShareAdapter extends V2ApiAdapter {
  @service router;

  handleResponse(status, headers, payload, requestData) {
    const shareId = requestData.url.split('/').lastObject;
    if ([401, 403, 404].includes(status)) {
      this.router.transitionTo('share.not-found', shareId);
      return;
    }
    if (status === 500) {
      this.router.transitionTo('share.error', shareId);
      return;
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
