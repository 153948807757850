/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {TransportEventModel} event
 */

export default class CargoTransportEvent extends Component {
  get isTransportEvent() {
    return !this.args.event.isRawEventsHeader && !this.args.event.isMissedCreationEventsHeader;
  }

  @action
  toggleExpansion() {
    if (this.args.event.isSureThingEvent) {
      return;
    }

    this.args.toggleExpansion(this.args.event.id);
  }
}
