import type JSONAPIAdapter from '@ember-data/adapter/json-api';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
//@ts-ignore
import V2ApiAdapter from './v2-api';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class FeatureFlagAdapter extends V2ApiAdapter {
  @service declare store: StoreService;

  async addAccount(id: string) {
    const response = await fetch(`${this.host}/v2/feature_flags/${id}/add_account`, {
      method: 'PATCH',
      headers: { ...this.headers },
    });

    const payload = await response.json();

    if (super.isSuccess(response.status, {}, {})) {
      this.store.pushPayload('feature-flag', payload);
    } else {
      throw payload.errors[0]?.detail;
    }
  }

  async removeAccount(id: string) {
    const response = await fetch(`${this.host}/v2/feature_flags/${id}/remove_account`, {
      method: 'PATCH',
      headers: { ...this.headers },
    });

    const payload = await response.json();

    if (super.isSuccess(response.status, {}, {})) {
      this.store.pushPayload('feature-flag', payload);
    } else {
      throw payload.errors[0]?.detail;
    }
  }

  async toggleAccount(id: string) {
    const response = await fetch(`${this.host}/v2/feature_flags/${id}/toggle_account`, {
      method: 'PATCH',
      headers: { ...this.headers },
    });

    const payload = await response.json();

    if (super.isSuccess(response.status, {}, {})) {
      this.store.pushPayload('feature-flag', payload);
    } else {
      throw payload.errors[0]?.detail;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface FeatureFlagAdapter extends JSONAPIAdapter {}

export default FeatureFlagAdapter;
