/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

interface TooltipIconSignature {
  Element: HTMLSpanElement;
  Args: {
    content: string;
    iconClasses: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
  };
}

export default class TooltipIcon extends Component<TooltipIconSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TooltipIcon: typeof TooltipIcon;
  }
}
