import Model, { attr, belongsTo, hasMany, type AsyncHasMany } from '@ember-data/model';
// @ts-ignore
import { apiAction } from '@mainmatter/ember-api-actions';
import type AccountModel from 'tnt-ui/models/account';
import type ContractDetailModel from 'tnt-ui/models/contract-detail';

interface History {
  changed_by: string;
  to_state?: string;
  feature_flag_key?: string;
  action?: string;
  created_at: string;
  reason?: string;
}

export default class BillingAccountModel extends Model {
  @belongsTo('contract-detail') declare contractDetail: ContractDetailModel;
  @belongsTo('account', { async: false }) declare primaryAccount: AccountModel;
  @hasMany('accounts', { async: false }) declare accounts: AsyncHasMany<AccountModel>;
  @hasMany('billing-subscription-item', { async: false }) declare billingSubscriptionItems: AsyncHasMany<any>;

  @attr('string') declare stripeCustomerId: string;
  @attr('string') declare domain: string;
  @attr('string') declare logoUrl: string;
  @attr('string') declare state: 'in_trial' | 'locked' | 'customer' | 'churned';
  @attr('date') declare endTrialDate: Date;
  @attr('date') declare startTrialDate: Date;
  @attr('date') declare contractStartDate: Date;
  @attr('date') declare contractEndDate: Date;
  @attr() declare lifecycleHistory: History[];

  get percentageTrialComplete() {
    return Math.floor(((this.totalTrialDays - this.trialDaysLeft) / this.totalTrialDays) * 100);
  }

  get totalTrialDays() {
    const startTrialDate = this.startTrialDate;
    const endTrialDate = this.endTrialDate;
    if (startTrialDate && endTrialDate) {
      return Math.ceil((endTrialDate.getTime() - startTrialDate.getTime()) / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get trialDaysLeft() {
    const today = new Date();
    const endTrialDate = this.endTrialDate;
    if (endTrialDate) {
      return Math.ceil((endTrialDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get isTrialActive() {
    return this.state === 'in_trial';
  }

  get isLocked() {
    return this.state === 'locked';
  }

  get isCustomer() {
    return this.state === 'customer';
  }

  get isChurned() {
    return this.state === 'churned';
  }

  get isChurnedCustomer() {
    return this.state === 'churned';
  }

  get humanizedState() {
    if (this.state === 'in_trial') {
      return 'Trial Active';
    }

    if (this.state === 'locked') {
      return 'Locked';
    }

    if (this.state === 'customer') {
      return 'Customer';
    }

    if (this.state === 'churned') {
      return 'Churned Customer';
    }

    return '';
  }

  async syncBillingSubscriptionItems() {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'sync_billing_subscription_items',
    });
  }

  async extendTrial(days: number, reason: string) {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'extend_trial',
      data: { reason, days },
    });
  }

  async endTrial(days: number) {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'end_trial',
    });
  }

  async toCustomer(reason: string, contractStartAt: Date) {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'to_customer',
      data: { reason, contract_start_date: contractStartAt },
    });
  }

  async toChurned(reason: string, contractEndAt: Date) {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'to_churned',
      data: { reason, contract_start_date: contractEndAt },
    });
  }

  async toLocked(reason: string) {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'to_locked',
      data: { reason },
    });
  }

  async backfill() {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'backfill',
    });
  }
}
