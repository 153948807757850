/* eslint-disable ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { bool, reads } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { toJSON } from 'tnt-ui/utils/toJSON';
import Validations from 'tnt-ui/utils/validations/user';

export default class UserModel extends Model.extend(Validations) {
  @tracked _currentAccount;

  @belongsTo('account', { async: false, inverse: null }) primaryAccount;
  @belongsTo('region', { async: false }) defaultRegion;
  @belongsTo('region', { async: false }) notificationRegion;
  @belongsTo('user-cargo-mapping') userCargoMapping;
  @hasMany('account', { async: false, inverse: 'users' }) accounts;
  @hasMany('user-cargo', { async: false }) userCargos;

  @attr('string') email;
  @attr('string') name;
  @attr('string') companyName;
  @attr('string') phoneNumber;
  @attr('string') jobRole;
  @attr('string') jobTitle;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean') emailVerified;
  @attr('date') activitiesLastSeenAt;
  @attr('number') unreadActivitiesCount;
  @attr('boolean') admin;
  @attr('boolean') disabled;
  @attr('string') role;
  @attr('string') referralSource;
  @attr({ defaultValue: () => [] }) customerNeeds;
  @attr('string') customerNeedsOther;
  @attr('string') completedCreateAccount;
  @attr('string') completedUserInfo;
  @attr('string') completedTrackShipments;
  @attr('string') completedScheduleMeeting;
  @attr('string') completedInviteUser;
  @attr({ defaultValue: () => [] }) activeContainerViewIds;

  roles = ['account_manager', 'member'];

  @attr({ defaultValue: () => ({}) }) notificationSettings;

  @reads('account.companyType') companyType;
  @bool('admin') isAdmin;

  @computed('updatedAt', 'createdAt')
  get wasJustCreated() {
    let diff = moment(this.updatedAt).diff(this.createdAt, 'seconds');
    return diff < 60;
  }

  @computed('name')
  get firstName() {
    const first = this.name?.split(' ') || [''];
    return first[0];
  }

  @computed('name')
  get lastName() {
    const first = this.name?.split(' ') || [''];
    return first[first.length - 1];
  }

  get firstNameInitial() {
    return this.firstName ? this.firstName.charAt(0) : '';
  }

  get lastNameInitial() {
    return this.lastName ? this.lastName.charAt(0) : '';
  }

  get initials() {
    return [this.firstNameInitial, this.lastNameInitial].filter((s) => isPresent(s)).join('');
  }

  @computed('accounts')
  get hasMultipleAccounts() {
    return this.accounts.length > 1;
  }

  @computed('role')
  get accountManager() {
    return this.role === 'account_manager';
  }

  get currentAccount() {
    if (isPresent(this._currentAccount)) {
      return this._currentAccount;
    } else {
      return this.primaryAccount;
    }
  }

  set currentAccount(account) {
    this._currentAccount = account;
  }

  async loadUserCargos() {
    await this.store.adapterFor('user').loadUserCargos(this);
  }

  toJSON() {
    return toJSON(this);
  }
}
