import type { RawEventName } from 'tnt-ui/models/raw-event';
import type { ExtendedTransportEventName } from 'tnt-ui/models/transport-event';

type RawEventMappings = Record<
  RawEventName,
  {
    name: string;
    transportEvent: ExtendedTransportEventName;
    alternativeTransportEvents?: ExtendedTransportEventName[];
  }
>;
export const rawEventMappings: RawEventMappings = {
  empty_out: {
    name: 'Empty Out',
    transportEvent: 'container.transport.empty_out',
  },
  full_in: {
    name: 'Full In',
    transportEvent: 'container.transport.full_in',
  },
  positioned_in: {
    name: 'Positioned In',
    transportEvent: 'raw',
  },
  positioned_out: {
    name: 'Positioned Out',
    transportEvent: 'raw',
  },
  vessel_loaded: {
    name: 'Vessel Loaded',
    transportEvent: 'container.transport.vessel_loaded',
  },
  vessel_departed: {
    name: 'Vessel Departed',
    transportEvent: 'container.transport.vessel_departed',
  },
  transshipment_arrived: {
    name: 'Transshipment Arrived',
    transportEvent: 'container.transport.transshipment_arrived',
  },
  transshipment_discharged: {
    name: 'Transshipment Discharged',
    transportEvent: 'container.transport.transshipment_discharged',
  },
  transshipment_loaded: {
    name: 'Transshipment Loaded',
    transportEvent: 'container.transport.transshipment_loaded',
  },
  transshipment_departed: {
    name: 'Transshipment Departed',
    transportEvent: 'container.transport.transshipment_departed',
  },
  rail_arrived: {
    name: 'Rail Arrived',
    transportEvent: 'container.transport.rail_arrived',
    alternativeTransportEvents: [
      'container.transport.estimated.arrived_at_inland_destination',
      'container.transport.arrived_at_inland_destination',
    ],
  },
  rail_loaded: {
    name: 'Rail Loaded',
    transportEvent: 'container.transport.rail_loaded',
  },
  rail_unloaded: {
    name: 'Rail Unloaded',
    transportEvent: 'container.transport.rail_unloaded',
    alternativeTransportEvents: ['container.transport.estimated.arrived_at_inland_destination'],
  },
  rail_departed: {
    name: 'Rail Departed',
    transportEvent: 'container.transport.rail_departed',
  },
  rail_interchange_received: {
    name: 'Rail Interchange Received',
    transportEvent: 'raw',
  },
  rail_interchange_delivered: {
    name: 'Rail Interchange Delivered',
    transportEvent: 'raw',
  },
  train_passing: {
    name: 'Train Passing',
    transportEvent: 'raw',
  },
  vessel_arrived: {
    name: 'Vessel Arrived',
    transportEvent: 'container.transport.vessel_arrived',
  },
  vessel_berthed: {
    name: 'Vessel Berthed',
    transportEvent: 'container.transport.vessel_berthed',
  },
  vessel_discharged: {
    name: 'Vessel Discharged',
    transportEvent: 'container.transport.vessel_discharged',
  },
  arrived_at_destination: {
    name: 'Arrived at Destination',
    transportEvent: 'container.transport.arrived_at_inland_destination',
    alternativeTransportEvents: ['container.transport.estimated.arrived_at_inland_destination'],
  },
  delivered: {
    name: 'Delivered',
    transportEvent: 'container.transport.delivered',
  },
  full_out: {
    name: 'Full Out',
    transportEvent: 'container.transport.full_out',
  },
  empty_in: {
    name: 'Empty In',
    transportEvent: 'container.transport.empty_in',
  },
  vgm_received: {
    name: 'VGM Received',
    transportEvent: 'raw',
  },
  carrier_release: {
    name: 'Carrier Release',
    transportEvent: 'raw',
  },
  customs_release: {
    name: 'Customs Release',
    transportEvent: 'raw',
  },
  feeder_arrived: {
    name: 'Feeder Arrived',
    transportEvent: 'container.transport.feeder_arrived',
  },
  feeder_loaded: {
    name: 'Feeder Loaded',
    transportEvent: 'container.transport.feeder_loaded',
  },
  feeder_departed: {
    name: 'Feeder Departed',
    transportEvent: 'container.transport.feeder_departed',
  },
  feeder_discharged: {
    name: 'Feeder Discharged',
    transportEvent: 'container.transport.feeder_discharged',
  },
  available: {
    name: 'Available',
    transportEvent: 'container.transport.available',
  },
  last_free_day_changed: {
    name: 'Last Free Day Changed',
    transportEvent: 'container.pickup_lfd.changed',
  },
};

type TransportEventMappings = Record<ExtendedTransportEventName, { name: string; rawEvents: RawEventName[] }>;
export const transportEventMappings = (): TransportEventMappings => {
  const acc: TransportEventMappings = {} as TransportEventMappings;
  const alternativeTransportEvents = [] as {
    transportEvent: ExtendedTransportEventName;
    rawEvent: RawEventName;
    name: string;
  }[];
  Object.entries(rawEventMappings).forEach(([key, value]) => {
    // put the primary events in first
    acc[value.transportEvent] = {
      name: value.name,
      rawEvents: [key as RawEventName],
    };
    // save the alternative events for later
    for (const transportEvent of value.alternativeTransportEvents ?? []) {
      alternativeTransportEvents.push({ transportEvent, rawEvent: key as RawEventName, name: value.name });
    }
  });
  // then add the alternative events
  for (const { transportEvent, rawEvent, name } of alternativeTransportEvents) {
    if (acc[transportEvent]) {
      acc[transportEvent].rawEvents.push(rawEvent);
    } else {
      acc[transportEvent] = {
        name,
        rawEvents: [rawEvent],
      };
    }
  }
  return acc;
};

export const matchCargoShipmentEventToTransportEvent = (event: string): { name: string; rawEvents: RawEventName[] } => {
  // If we don't removed `estimated.`, it matches up estimated.arrived_at_inland_destination incorrectly
  const eventName = event.replace('estimated.', '');
  return transportEventMappings()[eventName as ExtendedTransportEventName];
};
