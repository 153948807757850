/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import buildUnifiedEventTable from '../../../utils/build-unified-event-table';
import { matchCargoShipmentEventToTransportEvent } from '../../../utils/event-name-mappings';

/**
 * @param {ContainerModel} container
 */

export default class CargoUnifiedEventView extends Component {
  /* Session */
  @service session;

  get isAdmin() {
    return this.session.currentUser?.isAdmin;
  }

  /* Event History Modal */
  @tracked eventHistoryEvent = null;

  @action
  openEventHistoryModal(event, clickEvent) {
    this.eventHistoryEvent = event;
    clickEvent.stopPropagation();
  }

  /* Opening the invalidate modal */

  @tracked invalidateModalEvent = null;
  @tracked createModalEvent = null;
  @tracked editModalEvent = null;

  @action
  openInvalidateModal(event, clickEvent) {
    this.invalidateModalEvent = event;
    clickEvent.stopPropagation();
  }

  @action
  openCreateModal(event, clickEvent) {
    if (event == 'new') {
      this.createModalEvent = {};
    } else {
      const eventType = matchCargoShipmentEventToTransportEvent(event.event).rawEvents[0];
      this.createModalEvent = { event: eventType };
    }
    clickEvent.stopPropagation();
  }

  @action
  openEditModal(event, clickEvent) {
    this.editModalEvent = event;
    clickEvent.stopPropagation();
  }

  /* Expanding/condensing the All Events table */
  @tracked eventExpansions = {};

  @action
  toggleEventExpansion(eventId) {
    this.eventExpansions = {
      ...this.eventExpansions,
      [eventId]: !this.eventExpansions[eventId],
    };
  }

  @action
  toggleAllEventExpansions() {
    this.eventExpansions = this.unifiedEvents.reduce((acc, event) => {
      if (!event.isSureThingEvent) {
        acc[event.id] = !this.allExpanded;
      }
      if (event.intermediateRailHeader) {
        event.events.forEach((event) => {
          acc[event.id] = !this.allExpanded;
        });
      }
      return acc;
    }, {});
  }

  get allExpanded() {
    return this.unifiedEvents.every((event) => this.eventExpansions[event.id] || event.isSureThingEvent);
  }

  /* the main method for creating the All Events table */

  @cached
  get unifiedEvents() {
    return buildUnifiedEventTable(this.args.container);
  }

  /* Event Sidebar */

  get completelyFilled() {
    const completedEmptyInEvent = this.unifiedEvents.find((event) => {
      return event.event?.includes('empty_in') && event.isTransportEvent && event.timestamp;
    });
    return completedEmptyInEvent;
  }

  get mostRecentCompletedEventIndex() {
    return this.unifiedEvents.findIndex((event) => event.isMostRecentCompletedEvent);
  }
}
