/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class CargoInvalidateModal extends Component {
  @service fetch;
  @service notify;
  @service router;

  @tracked invalidationReason = '';
  @tracked error = null;

  get rawEvent() {
    if (this.args.event.isTransportEvent) {
      return this.args.event.sourceEvent;
    } else {
      return this.args.event;
    }
  }

  get transportEvent() {
    if (this.args.event.isTransportEvent) {
      return this.args.event;
    } else {
      // NOTE: If we're invalidating a raw event that's not the source, this will be null
      return this.args.container.transportEvents.find((event) => event.sourceEvent?.id === this.args.event.id);
    }
  }

  get disabled() {
    return this.invalidationReason.length < 5;
  }

  async refreshCurrentRoute() {
    const currentRoute = this.router.currentRouteName;
    await this.router.refresh(currentRoute);
  }

  @action
  setInvalidationReason(event) {
    this.invalidationReason = event.target.value;
  }

  @task
  *invalidate() {
    this.error = null;

    const url = `/v2/containers/${this.args.container.id}/raw_events/${this.rawEvent.id}/invalidate`;
    try {
      yield this.fetch.post(url, {
        data: { reason: this.invalidationReason },
      });

      this.invalidationReason = '';
      this.notify.success('Event invalidated successfully');

      // this only works if the shipment is loaded in the model in the route; so it works in /shipments/:id, but not in /containers/new/:view_id
      // Therefore, for now we only show the invalidate button in the shipment view
      yield this.refreshCurrentRoute();

      this.args.afterInvalidate();
    } catch (error) {
      this.error = `Failed to invalidate event: ${error.payload.error || error.payload.message}`;
      return;
    }
  }
}
