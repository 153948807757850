/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type RawEventModel from 'tnt-ui/models/raw-event';
import type TransportEventModel from 'tnt-ui/models/transport-event';
import type VesselModel from 'tnt-ui/models/vessel';

type ExpandedEventModel = (TransportEventModel | RawEventModel) & {
  // These are added by the UnifiedEventView component
  isMissedCreationEventsHeader: boolean;
  isMissedBecauseEstimated: boolean;
  isRawEventsHeader: boolean;
  estimated: boolean;
  isSourceEvent: boolean;
  isSureThingEvent: boolean;
  intermediateRailEvent: boolean;

  // These are required...
  previousVersion: ExpandedEventModel; // only on transportEvent
  isTransportEvent: boolean; // only on transportEvent
  dataSource: string; // only on rawEvent
  vessel: VesselModel; // they do a weird async thing by default
  willOccurAt: Date; // only on rawEvent
  rawEvents: ExpandedEventModel[]; // only on transportEvent
  dataProviderName: string; // only on rawEvent
  dataProviderCode: string; // only on rawEvent
  sourceEvent: ExpandedEventModel; // only on transportEvent
};

interface CargoEventSignature {
  Element: HTMLDivElement;
  Args: {
    event: ExpandedEventModel;
    isTopLevelEvent: boolean;
    showDataSource: boolean;
    childOfIntermediateRailEvent: boolean;
    openEventHistoryModal: (event: ExpandedEventModel) => void;
  };
}

class CargoEvent extends Component<CargoEventSignature> {
  @service declare timezone: any;

  get event() {
    // cursed code in order to get around the event sometimes being a proxy
    return this.args.event as { get(key: string): any };
  }

  get timestampRaw() {
    return this.event.get('willOccurAt') ?? this.event.get('timestamp');
  }

  get timestamp() {
    return this.timezone.dateInTz(this.timestampRaw, this.event.get('timezone'));
  }

  get updatedAt() {
    if (this.args.event.previousVersion) {
      return this.timezone.dateInTz(this.event.get('createdAt'), this.event.get('timezone'));
    }
  }

  get invalidatedAt() {
    return this.timezone.dateInTz(this.event.get('invalidatedAt'), this.event.get('timezone'));
  }

  get createdAt() {
    if (this.args.event.previousVersion) {
      return this.timezone.dateInTz(this.event.get('previousVersion.createdAt'), this.event.get('timezone'));
    } else {
      return this.timezone.dateInTz(this.event.get('createdAt'), this.event.get('timezone'));
    }
  }

  get timeZoneAbbr() {
    return this.timezone.timeZoneAbbr(this.event.get('createdAt'), this.event.get('timezone'));
  }

  get timestampTooltip() {
    if (this.event.get('estimated')) {
      return {
        content: `Estimated to occur at ${this.timestamp}`,
        placement: 'top',
      };
    }
    return {};
  }
}

export default CargoEvent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cargo::Event': typeof CargoEvent;
  }
}
