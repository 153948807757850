/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

/**
 * @param {ContainerModel} row
 * @param {Object} column
 * @param {String} value
 * @param {Array<ContainerModel>} [displayOnly]
 * @param {Boolean} [share]
 */
export default class ContainersContainerNumberComponent extends Component {
  @service store;
  @service session;
  @service router;
  @service modals;

  get shipmentURL() {
    const { row, share } = this.args;

    if (share) {
      return '#';
    } else {
      return this.router.urlFor('shipments.details', row.get('shipment.id'));
    }
  }

  onToggleUserContainer = task(async () => {
    const { row: container } = this.args;
    let userCargo = await container.get('userCargo');

    if (userCargo) {
      await userCargo.destroyRecord();

      this.args.trackEvent({
        event: 'My Containers Removed',
        container_id: container.get('id'),
        shipment_id: container.get('shipment.id'),
      });
    } else {
      userCargo = this.store.createRecord('user-container', {
        cargo: container,
        user: this.session.currentUser,
      });

      await userCargo.save();

      this.args.trackEvent({
        event: 'My Containers Added',
        container_id: container.get('id'),
        shipment_id: container.get('shipment.id'),
      });
    }
  });

  @action
  onClick(e) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();
    const { row, viewId, isAnonymized } = this.args;

    this.modals.open('container-details', {
      viewId: viewId,
      isAnonymized: isAnonymized,
      shipmentId: row.get('shipment.id'),
      selectedContainer: row,
      fromContainer: true,
    });

    this.args.trackEvent({
      event: 'shipment_details_open_from_container',
    });
  }

  @action
  copyToClipboard() {
    const { value } = this.args;

    navigator.clipboard.writeText(value);

    this.args.trackEvent({
      event: 'copy_to_clipboard',
      value: value,
    });
  }
}
