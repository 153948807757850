/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class CargoEventSidebar extends Component {
  get heightAndRounding() {
    const position = this.args.position;

    if (position === 'top') {
      return 'tw-rounded-t-full tw-h-4';
    } else if (position === 'middle') {
      return 'tw-h-full';
    } else if (position === 'bottom') {
      return 'tw-rounded-b-full tw-h-4';
    }
    return '';
  }

  get fillClasses() {
    if (this.args.isFilled) {
      return 'tw-bg-green-500';
    } else if (this.args.partlyFilled) {
      return 'tw-bg-green-200';
    }
    return 'tw-bg-gray-200';
  }

  get bigArrowBorder() {
    // This is to make the top rounded edge match the color of the EventSidebar segments above it.
    // BigArrow shows on the last event, so the event above it is usually grey.
    // The exception is if EventSidebar is completely filled, the top rounded edge should be green (the default fill color).
    if (this.args.bigArrow && !this.args.completelyFilled) {
      return 'tw-border-t-2 tw-border-gray-200';
    }
    return '';
  }
}
