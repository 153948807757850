/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class ActivityListItemComponent extends Component {
  @service router;

  iconClasses = {
    'booking-order-created': 'fa fa-truck fa-flip-horizontal',
    'booking-order-received': 'fa fa-truck fa-flip-horizontal',
    'new-message': 'fa fa-commenting fa-flip-horizontal',
    'admin-message': 'fa fa-commenting fa-flip-horizontal',
    'cargo-status-update': 'fa fa-cubes',
    'invite-team-member-accepted': 'fa fa-user',
    'invite-team-member-created': 'fa fa-user',
    'invite-business-partner-created': 'fa fa-briefcase',
    'invite-business-partner-accepted': 'fa fa-briefcase',
    'document-uploaded': 'fa fa-file-text-o',
    'booking-order-populate': 'fa fa-pencil-square-o',
    'booking-order-invoice': 'fa fa-file-text-o',
    'offer-update': 'fa fa-envelope-o',
    'feature-flag-enabled': 'fa fa-check-circle',
    'feature-flag-disabled': 'fa fa-times-circle',
  };

  showDetails = this.args.showDetails || true;
  linked = this.args.linked || false;

  get unseenByUser() {
    return this.args.model.createdAt > this.currentUser?.activitiesLastSeenAt;
  }

  get activityName() {
    return this.args.model.name;
  }

  get teamMemberInviteNew() {
    return isEqual(this.activityName, 'invite-team-member-created');
  }

  get teamMemberInviteAccepted() {
    return isEqual(this.activityName, 'invite-team-member-accepted');
  }

  get businessPartnerInviteNew() {
    return isEqual(this.activityName, 'invite-business-partner-created');
  }

  get bookingOrderPopulate() {
    return isEqual(this.activityName, 'booking-order-populate');
  }

  get bookingOrderInvoice() {
    return isEqual(this.activityName, 'booking-order-invoice');
  }

  get bookingParticipantInvitedNew() {
    return isEqual(this.activityName, 'invite-booking-participant-created');
  }

  get bookingParticipantInvitedAccepted() {
    return isEqual(this.activityName, 'invite-booking-participant-accepted');
  }

  get newMessage() {
    return isEqual(this.activityName, 'new-message');
  }

  get adminMessage() {
    return isEqual(this.activityName, 'admin-message');
  }

  get cargoStatusUpdated() {
    return isEqual(this.activityName, 'cargo-status-update');
  }

  get bookingOrderStatusUpdated() {
    return isEqual(this.activityName, 'booking-order-status-update');
  }

  get bookingOrderCreated() {
    return isEqual(this.activityName, 'booking-order-created');
  }

  get bookingOrderReceived() {
    return isEqual(this.activityName, 'booking-order-received');
  }

  get documentUploaded() {
    return isEqual(this.activityName, 'document-uploaded');
  }

  get documentArchived() {
    return isEqual(this.activityName, 'document-archived');
  }

  get cargoAppointmentChanged() {
    return isEqual(this.activityName, 'cargo-appointment-changed');
  }

  get containersAssigned() {
    return isEqual(this.activityName, 'containers-assigned');
  }

  get featureFlagEnabled() {
    return isEqual(this.activityName, 'feature-flag-enabled');
  }

  get featureFlagDisabled() {
    return isEqual(this.activityName, 'feature-flag-disabled');
  }

  get featureFlagName() {
    return this.args.model.meta['key'];
  }

  get myContainers() {
    return isEqual(this.featureFlagName, 'my-containers');
  }

  get relatedModel() {
    let target = this.args.model.targetType;
    if (target == 'Cargo') {
      return 'Container #';
    } else if (target == 'BookingOrder') {
      return 'Delivery Order #';
    } else {
      return 'Shipment ';
    }
  }

  get isBookingRelated() {
    return (
      this.bookingOrderCreated ||
      this.bookingOrderReceived ||
      this.cargoStatusUpdated ||
      this.bookingOrderStatusUpdated ||
      this.newMessage ||
      this.bookingParticipantInvitedNew ||
      this.bookingParticipantInvitedAccepted ||
      this.documentUploaded ||
      this.documentArchived ||
      this.adminMessage ||
      this.offerUpdated
    );
  }

  get isAccountRelated() {
    return this.teamMemberInviteNew || this.teamMemberInviteAccepted;
  }

  get showBookingDetail() {
    return this.showDetails && this.isBookingRelated;
  }

  get offerUpdated() {
    return isEqual(this.activityName, 'offer-update');
  }

  get activityIconClass() {
    return this.iconClasses[this.activityName] || 'fa fa-plus-square';
  }

  get userName() {
    return this.args.model.userName.split(/\s/)[0];
  }

  @action
  onClick() {
    if (this.linked) {
      if (this.args.model.targetType == 'Shipment') {
        this.args.gotoSubject(this.args.model.get('subject.id'), 'shipment');
      } else if (this.isBookingRelated && !this.offerUpdated) {
        this.args.gotoSubject(this.args.model.get('subject.id'), 'bookingOrder');
      } else if (this.offerUpdated) {
        this.args.gotoSubject(this.args.model.get('subject.id'), 'offer');
      } else if (this.isAccountRelated) {
        this.args.gotoSubject(null, 'account');
      } else if (this.featureFlagEnabled || this.featureFlagDisabled) {
        if (this.myContainers) {
          this.router.transitionTo('settings.my-containers');
        }
      }
    }
  }
}
