import { compare } from '@ember/utils';
import { matchCargoShipmentEventToTransportEvent } from './event-name-mappings';

export default function buildUnifiedEventTable(container) {
  let remainingRawEvents = container?.rawEvents.slice() ?? [];
  const transportEvents = (container?.transportEvents.slice() ?? []).sort((a, b) => compare(b.timestamp, a.timestamp));
  const actualTransportEvents = transportEvents.filter(({ event }) => !event.includes('estimated'));
  const withoutDuplicates = peelOffDuplicates(actualTransportEvents);
  const currentTransportEvents = peelOffPreviousVersions(withoutDuplicates);
  const invalidatedResults = connectSuccessfulTransportEvents(
    remainingRawEvents.filter((event) => event.invalidatedAt),
    currentTransportEvents.filter((event) => event.invalidatedAt),
  );
  const actualResults = connectSuccessfulTransportEvents(
    [...remainingRawEvents.filter((event) => !event.invalidatedAt), ...invalidatedResults.remainingRawEvents],
    currentTransportEvents.filter((event) => !event.invalidatedAt),
  );
  const estimatedResults = connectSuccessfulTransportEvents(
    actualResults.remainingRawEvents,
    estimatedTransportEvents(transportEvents),
    true,
  );

  let events = [
    ...invalidatedResults.connectedTransportEvents,
    ...actualResults.connectedTransportEvents,
    ...estimatedResults.connectedTransportEvents,
    ...estimatedResults.remainingRawEvents,
  ];

  events = fixLfdTransportEvents(events);
  events = markAndFilterEstimatedTransportEvents(events);
  events = events.sort((a, b) =>
    compare(
      typeof b.timestamp === 'string' ? b.timestamp : b.timestamp?.toISOString(),
      typeof a.timestamp === 'string' ? a.timestamp : a.timestamp?.toISOString(),
    ),
  );

  const unifiedEvents = orderEventsAndGroupRawEvents(events);
  const markedEvents = markMostRecentCompletedEvent(markEventsAsIntermediateRail(unifiedEvents));
  const withSureThingEvents = integrateSureThingEvents(markedEvents);
  const withLfdChangeHandled = handleLfdChangeEvents(withSureThingEvents);
  return withLfdChangeHandled;
}

const handleLfdChangeEvents = (unifiedEvents) => {
  const lfdChangeEventIndex = unifiedEvents.findIndex((event) => event.event === 'container.pickup_lfd.changed');
  const fullOutEventIndex = unifiedEvents.findIndex((event) => event.event === 'container.transport.full_out');

  if (lfdChangeEventIndex !== -1 && fullOutEventIndex !== -1 && lfdChangeEventIndex < fullOutEventIndex) {
    // put the lfdChanged event right before the fullOut event
    const lfdChangeEvent = unifiedEvents[lfdChangeEventIndex];
    unifiedEvents.splice(lfdChangeEventIndex, 1);
    unifiedEvents.splice(fullOutEventIndex, 0, lfdChangeEvent);
  }

  return unifiedEvents;
};

const integrateSureThingEvents = (unifiedEvents) => {
  // This is a good test case, because it's missing vessel_departed http://localhost:4200/shipments/81688272-0231-4ed1-ac01-e4b479388799
  const eventOrder = [
    { name: 'delivered' },
    { name: 'empty_in', isSureThingEvent: true },
    { name: 'full_out', isSureThingEvent: true },
    { name: 'estimated.arrived_at_destination' },
    { name: 'changed' }, // pickup LFD changed
    { name: 'rail_unloaded' },
    { name: 'customs_release' },
    { name: 'arrived_at_destination' },
    { name: 'arrived_at_inland_destination' },
    { name: 'rail_arrived' },
    { name: 'rail_interchange_delivered' },
    { name: 'rail_interchange_received' },
    { name: 'rail_departed' },
    { name: 'rail_loaded' },
    { name: 'vessel_unloaded' },
    { name: 'vessel_discharged' },
    { name: 'vessel_berthed' },
    { name: 'vessel_arrived', isSureThingEvent: true },
    { name: 'transshipment_loaded' },
    { name: 'transshipment_departed' },
    { name: 'transshipment_discharged' },
    { name: 'transshipment_arrived' },
    { name: 'vessel_departed', isSureThingEvent: true },
    { name: 'vessel_loaded' },
    { name: 'full_in', isSureThingEvent: true },
    { name: 'empty_out', isSureThingEvent: true },
  ];
  const indexedEventOrder = eventOrder.map((event, index) => ({ ...event, index }));

  const sureThingEventTypes = eventOrder.filter((event) => event.isSureThingEvent);

  // First, check which sure thing events are missing
  const missingEvents = sureThingEventTypes
    .map((eventType) => {
      const hasSureThingEvent = unifiedEvents.some((event) => event.event?.split('.').pop() === eventType.name);
      if (!hasSureThingEvent) {
        const event =
          eventType.name === 'changed' ? 'container.pickup_lfd.changed' : `container.transport.${eventType.name}`;
        return {
          event,
          shortName: eventType.name,
          humanizedEventName: matchCargoShipmentEventToTransportEvent(event).name,
          isSureThingEvent: true,
          rawEvents: [],
        };
      }
    })
    .compact();

  if (missingEvents.length === 0) {
    return unifiedEvents;
  }

  missingEvents.forEach((missingEvent) => {
    const eventOrderIndex = indexedEventOrder.findIndex((e) => e.name === missingEvent.shortName);
    const eventTypesAfterMissingEvent = indexedEventOrder.filter((e) => e.index > eventOrderIndex);

    const firstEventIndexThatCouldComeAfterMissingEvent = unifiedEvents.findIndex((e) => {
      const eventName = e.event?.split('.').pop();
      return eventTypesAfterMissingEvent.some((e2) => e2.name === eventName);
    });

    if (firstEventIndexThatCouldComeAfterMissingEvent !== -1) {
      unifiedEvents.splice(firstEventIndexThatCouldComeAfterMissingEvent, 0, missingEvent);
    } else {
      // If no events exist that could come after the missing event, it means it's the last event, so we just add it to the end
      unifiedEvents.push(missingEvent);
    }
  });
  return unifiedEvents;
};

const markEventsAsIntermediateRail = (unifiedEvents) => {
  const MIN_RAIL_EVENTS_FOR_INTERMEDIATE = 5;

  const railEventTypes = [
    'rail_loaded',
    'rail_unloaded',
    'rail_departed',
    'rail_arrived',
    'train_passing',
    'rail_interchange_received',
    'rail_interchange_delivered',
    'arrived_at_inland_destination',
  ];

  const newEventsArray = [];
  let currentRailArray = [];

  const pushExistingRailEvents = () => {
    if (currentRailArray.length >= MIN_RAIL_EVENTS_FOR_INTERMEDIATE) {
      newEventsArray.push(currentRailArray[0]);
      const intermediateRailEvents = currentRailArray.slice(1, currentRailArray.length - 1);
      intermediateRailEvents.forEach((event) => {
        event.intermediateRailEvent = true;
      });
      newEventsArray.push({
        intermediateRailHeader: true,
        events: intermediateRailEvents,
        isExpanded: false,
        get() {
          return;
        },
      });
      newEventsArray.push(currentRailArray[currentRailArray.length - 1]);
    } else {
      newEventsArray.push(...currentRailArray);
    }
  };

  unifiedEvents.forEach((event) => {
    const isRailEvent = railEventTypes.includes(event.event?.split('.')?.pop());
    const estimatedArrivedAtInlandDestination =
      event.event === 'container.transport.estimated.arrived_at_inland_destination';
    if (isRailEvent && !estimatedArrivedAtInlandDestination) {
      currentRailArray.push(event);
    } else {
      pushExistingRailEvents();
      currentRailArray = [];
      newEventsArray.push(event);
    }
  });

  pushExistingRailEvents();

  return newEventsArray;
};

const markMostRecentCompletedEvent = (unifiedEvents) => {
  const lastActualIndex = unifiedEvents.findIndex(
    (event) =>
      !event.estimated && !event.isMissedCreationEventsHeader && event.event !== 'container.pickup_lfd.changed',
  );

  if (lastActualIndex !== -1) {
    unifiedEvents[lastActualIndex].isMostRecentCompletedEvent = true;
  }

  return unifiedEvents;
};

/* Helper methods */

const estimatedTransportEvents = (transportEvents) => {
  const events = [];
  const estimatedTransportEvents = transportEvents.filter(({ event }) => event.includes('estimated'));
  estimatedTransportEvents
    .sort((a, b) => compare(b.createdAt, a.createdAt))
    .forEach((event) => {
      if (!events.find((e) => e.locationLocode === event.locationLocode && e.event === event.event)) {
        events.push(event);
      }
    });
  return events;
};

const timestampsEqual = (date1, date2) => {
  const d1 = date1 instanceof Date ? date1 : new Date(date1);
  const d2 = date2 instanceof Date ? date2 : new Date(date2);
  return d1.getTime() === d2.getTime();
};

const shouldRawEventBeConnected = (rawEvent, transportEvent, estimated = false) => {
  if (!estimated) {
    // running transportEvent.sourceEvent on an estimated event throws an adapter error, so we prevent that by not running the sourceEvent matchup when estimated is true
    if (rawEvent.id === transportEvent.sourceEvent?.id) {
      return true;
    }
  }

  const sameTimestamp = timestampsEqual(rawEvent.timestamp, transportEvent.timestamp);
  const sameLocation = rawEvent.locationLocode == transportEvent.locationLocode;
  if (estimated) {
    return rawEvent.possibleTransportEvents.includes(transportEvent.event) && sameLocation;
  } else {
    return rawEvent.transportEvent == transportEvent.event && (sameLocation || sameTimestamp);
  }
};

const connectSuccessfulTransportEvents = (rawEvents, transportEvents, estimated = false) => {
  let remainingRawEvents = rawEvents;
  const connectedTransportEvents = transportEvents
    .map((event) => {
      const rawEventsToConnect = remainingRawEvents.filter((rawEvent) =>
        shouldRawEventBeConnected(rawEvent, event, estimated),
      );
      rawEventsToConnect.forEach((rawEvent) => {
        if (rawEvent.id === event.sourceEvent?.id) {
          rawEvent.isSourceEvent = true;
        }
      });
      event.rawEvents = rawEventsToConnect.sort((a, b) => compare(a.createdAt, b.createdAt));
      remainingRawEvents = remainingRawEvents.filter((rawEvent) => {
        return !rawEventsToConnect.includes(rawEvent);
      });
      return event;
    })
    .compact();
  return {
    connectedTransportEvents,
    remainingRawEvents,
  };
};

const peelOffDuplicates = (transportEvents) => {
  // Certain transport events, such as 'container.pickup_lfd.changed', can have multiple previous versions that are NOT invalidated or tracked with `previousVersion`
  // We only want to show the latest version (per location), so we need to peel off those duplicates as well
  // Later, we may decide to fix this on the backend by automatically invalidating older events and setting `previousVersion`, but that would be a trickier task and would change what API customers see
  // If we fix it on the backend, and include a rake task to backfill previous shipments, we can remove this code
  // More discussion here: https://linear.app/terminal49/issue/DATA-4735/extra-last-free-day-transport-event#comment-0be3fe17
  const eventTypesToCheckForDuplicates = [
    'container.pickup_lfd.changed',
    'container.transport.estimated.arrived_at_inland_destination',
  ];
  let latestVersions = [];
  transportEvents.forEach((event) => {
    if (eventTypesToCheckForDuplicates.includes(event.event)) {
      const existingEvent = latestVersions.find(
        (e) => e.locationLocode === event.locationLocode && e.event === event.event,
      );
      if (!existingEvent) {
        latestVersions.push(event);
      } else if (compare(event.createdAt, existingEvent.createdAt) > 0) {
        latestVersions = latestVersions.filter((e) => e !== existingEvent);
        latestVersions.push(event);
      }
    }
  });
  return transportEvents.reject((event) => {
    return eventTypesToCheckForDuplicates.includes(event.event) && !latestVersions.includes(event);
  });
};

const peelOffPreviousVersions = (transportEvents) => {
  const previousVersionIds = transportEvents.map((event) => event.previousVersion?.id).compact();
  return transportEvents.filter((event) => !previousVersionIds.includes(event.id));
};

const fixLfdTransportEvents = (transportEvents) => {
  return transportEvents.map((event) => {
    if (event.event === 'container.pickup_lfd.changed') {
      const rawEvent = event.rawEvents[0];
      event.willOccurAt = rawEvent?.willOccurAt || rawEvent?.actualAt || 'n/a'; // this will show 'Invalid Date', which is better than an incorrect date
    }
    return event;
  });
};

const markAndFilterEstimatedTransportEvents = (transportEvents) => {
  return transportEvents
    .map((event) => {
      if (event.event.includes('estimated')) {
        event.estimated = true;

        if (event.rawEvents.length === 0) {
          // remove estimated transport events whose raw events have been matched with an actual transport event
          return null;
        }
      }
      return event;
    })
    .compact();
};

const createUnifiedEvent = (unaddedRawEvents) => {
  const firstEvent = unaddedRawEvents[0];
  const isEstimated = unaddedRawEvents.every((event) => event.estimated);
  const isMissed = firstEvent.eventType === 'missed';
  const isFloating = firstEvent.eventType === 'floating';
  const eventWithEstimatedTransportEvent = ['rail_unloaded', 'rail_arrived', 'arrived_at_inland_destination'].includes(
    firstEvent.event,
  );
  const isMissedBecauseEstimated = isEstimated && isMissed && !eventWithEstimatedTransportEvent;

  return {
    rawEvents: unaddedRawEvents.sort((a, b) => compare(a.createdAt, b.createdAt)),
    isRawEventsHeader: isFloating,
    isMissedCreationEventsHeader: isMissed,
    event: firstEvent.event,
    humanizedEventName: firstEvent.humanizedEventName,
    timestamp: firstEvent.timestamp,
    timezone: firstEvent.timezone,
    id: firstEvent.id, // needed for toggle expansion, so it doesn't toggle all placeholder unified events when one is clicked
    estimated: isEstimated,
    isMissedBecauseEstimated,
    locationName: firstEvent.locationName,
    locationLocode: firstEvent.locationLocode,
    dataSource: firstEvent.dataSource,
    dataProviderName: firstEvent.dataProviderName,
    dataProviderCode: firstEvent.dataProviderCode,
    invalidatedAt: firstEvent.invalidatedAt, // for estimated events
    get(key) {
      return this[key];
    },
  };
};

const orderEventsAndGroupRawEvents = (events) => {
  const unifiedEvents = [];
  let unaddedRawEvents = [];

  events.forEach((event) => {
    event.eventType = determineEventType(event);

    const differentEventType = unaddedRawEvents.length && unaddedRawEvents[0].event !== event.event;

    if (differentEventType) {
      unifiedEvents.push(createUnifiedEvent(unaddedRawEvents));
      unaddedRawEvents = [];
    }

    if (event.isTransportEvent) {
      unifiedEvents.push(event);
    } else {
      unaddedRawEvents.push(event);
    }
  });
  if (unaddedRawEvents.length) {
    unifiedEvents.push(createUnifiedEvent(unaddedRawEvents));
  }
  return unifiedEvents;
};

const determineEventType = (event) => {
  if (event.isTransportEvent) return 'transport';
  if (event.transportEvent === 'raw') return 'floating';
  return 'missed';
};
