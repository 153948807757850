/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';

interface InputSmartValidateSignature {
  Element: HTMLElement;
  Args: {
    labelText: string;
    labelClass?: string;
    placeholder?: string;
    formSubmitted?: boolean;
    inputType?: string;
    value?: string;
    setAttribute: (value: string) => void;
    validate: () => void;
    errors: Array<{ message: string }>;
  };
  Blocks: {};
}

class InputSmartValidateComponent extends Component<InputSmartValidateSignature> {
  @tracked fieldSubmitted = false;

  get hideErrors() {
    return !this.fieldSubmitted && !this.args.formSubmitted;
  }

  get errors() {
    if (this.hideErrors) {
      return [];
    }

    return this.args.errors?.map((error) => error.message) || [];
  }

  @action
  validate() {
    this.fieldSubmitted = true;
    this.args.validate();
  }
}

export default InputSmartValidateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InputSmartValidate: typeof InputSmartValidateComponent;
  }
}
