/* import __COLOCATED_TEMPLATE__ from './setup-my-containers.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ModalsSetupMyContainers extends Component {
  @service modals;
  @service metrics;

  @action
  onModalClose() {
    this.metrics.trackEvent({
      event: 'Early Access Modal Closed',
      feature_flag_key: 'my-containers',
    });
    this.modals.close('setup-my-containers');
  }
}
