/* import __COLOCATED_TEMPLATE__ from './tell-us-about.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ModalsTellUsAbout extends Component {
  @service modals;

  @action
  onModalClose() {
    this.modals.close('tell-us-about');
  }
}
