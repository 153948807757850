/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class CargoUnifiedEvent extends Component {
  @service router;
  @service session;

  get showInvalidateButton() {
    return this.isAdmin && this.isAcceptableRoute;
  }

  get showEditButton() {
    return this.isAdmin && this.isAcceptableRoute;
  }

  get showCreateButton() {
    return this.isAdmin && this.isAcceptableRoute;
  }

  get isAdmin() {
    return this.session.currentUser?.isAdmin;
  }

  get isAcceptableRoute() {
    const currentRoute = this.router.currentRoute;
    const acceptableRoutes = ['shipments.details'];
    return acceptableRoutes.includes(currentRoute?.name);
  }
}
