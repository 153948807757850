import type JSONAPIAdapter from '@ember-data/adapter/json-api';
import type StoreService from '@ember-data/store';
import { service } from '@ember/service';
import UserModel from 'tnt-ui/models/user';
//@ts-ignore
import ApplicationAdapter from './application';

class UserAdapter extends ApplicationAdapter {
  @service declare store: StoreService;

  async loadUserCargos(user: UserModel) {
    const response = await fetch(`${this.host}/v2/users/${user.id}/user_cargos`, {
      method: 'GET',
      headers: { ...this.headers },
    });

    const payload = await response.json();

    if (super.isSuccess(response.status, {}, {})) {
      this.store.unloadAll('user-cargo');
      this.store.pushPayload('user-cargo', payload);
      await this._loadUserContainers(JSON.parse(JSON.stringify(payload)));
    } else {
      throw payload.errors[0]?.detail;
    }
  }

  async _loadUserContainers(payload: any) {
    const userContainers: any[] = Array.isArray(payload.data) ? payload.data : [payload.data];

    userContainers.forEach((container: any) => {
      container.type = 'user_container';
      container.relationships.cargo.data.type = 'container';
    });

    this.store.unloadAll('user-container');
    this.store.pushPayload('user-container', payload);
  }
}

interface UserAdapter extends JSONAPIAdapter {}

export default UserAdapter;
