import Model, { attr } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import type MetricsService from 'ember-metrics/services/metrics';
import type SessionService from 'tnt-ui/services/session';

export default class FeatureFlagModel extends Model {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare metrics: MetricsService;

  @attr('string') declare key: string;
  @attr('string') declare name: string;
  @attr declare accountIds: string[];
  @attr('boolean') declare earlyAccess: boolean;
  @attr('string') declare earlyAccessFeatureId: string;

  get currentAccount() {
    return this.session.currentUser.currentAccount;
  }

  get accountIncluded() {
    return (this.accountIds ?? []).includes(this.currentAccount?.id);
  }

  async addAccount() {
    const adapter = this.store.adapterFor('feature-flag' as never) as any;
    await adapter.addAccount(this.id);

    this.trackEvent({ event: 'Feature Flag Enabled' });
  }

  async removeAccount() {
    const adapter = this.store.adapterFor('feature-flag' as never) as any;
    await adapter.removeAccount(this.id);

    this.trackEvent({ event: 'Feature Flag Disabled' });
  }

  async toggleAccount() {
    const adapter = this.store.adapterFor('feature-flag' as never) as any;
    await adapter.toggleAccount(this.id);

    this.trackEvent({ event: 'feature_flag_toggled' });
  }

  trackEvent(attrs: {}) {
    this.metrics.trackEvent({
      ...attrs,
      feature_flag_key: this.key,
      account_id: this.currentAccount?.id,
      account_name: this.currentAccount?.companyName,
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'feature-flag': FeatureFlagModel;
  }
}
