import Model, { attr, belongsTo } from '@ember-data/model';
import type ContainerModel from 'tnt-ui/models/container';
import type VesselModel from 'tnt-ui/models/vessel';
import { rawEventMappings } from '../utils/event-name-mappings';
import type PortModel from './port';
import type { ExtendedTransportEventName } from './transport-event';

export type RawEventName =
  | 'empty_out'
  | 'full_in'
  | 'positioned_in'
  | 'positioned_out'
  | 'vessel_loaded'
  | 'vessel_departed'
  | 'transshipment_arrived'
  | 'transshipment_discharged'
  | 'transshipment_loaded'
  | 'transshipment_departed'
  | 'rail_arrived'
  | 'rail_loaded'
  | 'rail_unloaded'
  | 'rail_departed'
  | 'rail_interchange_received'
  | 'rail_interchange_delivered'
  | 'train_passing'
  | 'vessel_arrived'
  | 'vessel_berthed'
  | 'vessel_discharged'
  | 'arrived_at_destination'
  | 'delivered'
  | 'full_out'
  | 'empty_in'
  | 'vgm_received'
  | 'carrier_release'
  | 'customs_release'
  | 'feeder_arrived'
  | 'feeder_loaded'
  | 'feeder_departed'
  | 'feeder_discharged'
  | 'available'
  | 'last_free_day_changed';

export default class RawEventModel extends Model {
  @belongsTo('vessel', { async: false }) declare vessel: VesselModel;
  @belongsTo('port', { async: false, polymorphic: true }) declare location: PortModel;
  @belongsTo('container', { async: false }) declare container: ContainerModel;

  @attr('date') declare actualOn?: Date;
  @attr('date') declare estimatedAt: Date;
  @attr('date') declare actualAt?: Date;
  @attr('date') declare willOccurAt?: Date; // only for LFD
  @attr('string') declare event: string;
  @attr('string') declare originalEvent: string;
  @attr('string') declare locationName: string;
  @attr('string') declare locationLocode: string;
  @attr('string') declare voyageNum: string;
  @attr('number') declare index: number;
  @attr('string') declare timezone: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare dataSource: string;
  @attr('date') declare invalidatedAt: Date;
  @attr('string') declare invalidationReason: string;
  @attr('string') declare dataProviderName: string;
  @attr('string') declare dataProviderCode: string;

  get estimated() {
    return this.actualOn == null && this.actualAt == null && this.estimatedAt != null;
  }

  get possibleTransportEvents(): ExtendedTransportEventName[] {
    const mapping = rawEventMappings[this.event as RawEventName];
    return [mapping.transportEvent, ...(mapping.alternativeTransportEvents || [])];
  }

  // Should be safe to assume that the first possible transport event is the standard one??
  get transportEvent(): ExtendedTransportEventName {
    return rawEventMappings[this.event as RawEventName]?.transportEvent;
  }

  get humanizedEventName(): string {
    return rawEventMappings[this.event as RawEventName]?.name;
  }

  get voyageNumber() {
    return this.voyageNum;
  }

  get timestamp() {
    return this.actualOn ?? this.actualAt ?? this.estimatedAt ?? this.willOccurAt;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'raw-event': RawEventModel;
  }
}
