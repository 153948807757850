/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { objectValidator, type ValidatedObject, type ValidationsConfig } from 'ember-model-validator';
import type NotifyService from 'ember-notify/services/notify';
import type OnboardingService from 'tnt-ui/services/onboarding';
import type SessionService from 'tnt-ui/services/session';

interface OnboardingUserInfoFormSignature {
  Element: HTMLFormElement;
  Args: {
    closeModal?: () => {};
  };
  Blocks: {
    default: [];
  };
}

@objectValidator
class OnboardingUserInfoForm extends Component<OnboardingUserInfoFormSignature> {
  @service declare session: SessionService;
  @service declare onboarding: OnboardingService;
  @service declare notify: NotifyService;

  @tracked jobTitle = '';
  @tracked businessType: { name: string; id: string } | undefined;
  @tracked containersPerMonth: string | undefined;
  @tracked referralSource = '';
  @tracked referralSourceOther = '';
  @tracked customerNeeds: string[] = [];
  @tracked customerNeedsOther = '';

  @tracked formSubmitted = false;
  @tracked serverErrors: string[] = [];

  businessTypes = [
    {
      name: 'Cargo Owner',
      id: 'shipper',
    },
    {
      name: 'Service Provider',
      id: 'freight_forwarder',
    },
    {
      name: 'Trucking Company',
      id: 'trucking_company',
    },
    {
      name: 'Logistics Software',
      id: 'software_company',
    },
    {
      name: 'Other',
      id: 'other',
    },
  ];

  containersPerMonthRange = [
    'Less than 10',
    '10-50',
    '51-100',
    '101-500',
    '501-1000',
    '1001-5000',
    '5001-10000',
    'Over 10000',
  ];

  referralSources = ['Google Search', 'Customer Referral', 'Linkedin', 'JOC', 'Freightwaves', 'Conference', 'Other'];

  helpOptions = [
    { text: 'Ocean tracking', icon: 'fa-eye' },
    { text: 'Intermodal rail tracking', icon: 'fa-train' },
    { text: 'Improve operations', icon: 'fa-chart-bar' },
    { text: 'Prevent demurrage/per diem', icon: 'fa-monitor-waveform' },
    { text: 'Share data internally/externally', icon: 'fa-chart-network' },
    {
      text: 'Integrate data to TMS/ERP/other',
      svg: `<svg width="25" height="19" viewBox="0 0 25 19" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.09636 2.05177C8.5916 0.721139 10.5337 -0.00985758 12.5434 0.000100415L12.5436 0.000101577C14.2764 0.00946127 15.9619 0.563628 17.3562 1.58327C18.6697 2.5438 19.6609 3.87072 20.2041 5.39135C21.3895 5.66141 22.4532 6.31849 23.2202 7.26235C24.051 8.2847 24.4762 9.57288 24.4145 10.8847C24.3528 12.1965 23.8086 13.4398 22.8857 14.3812C21.9629 15.3225 20.7253 15.8971 19.4057 15.9985C19.393 15.9995 19.3802 16 19.3674 16H4.95585C4.94016 16 4.92448 15.9993 4.90886 15.9978C3.74884 15.8883 2.67058 15.3551 1.8851 14.5008C1.09947 13.6463 0.66333 12.5319 0.66333 11.375C0.66333 10.2181 1.09947 9.10374 1.8851 8.24925C2.57931 7.49417 3.50223 6.98999 4.50816 6.80743C4.78703 4.97663 5.70008 3.29431 7.09636 2.05177ZM7.76114 2.7988C9.07093 1.63321 10.7741 0.9914 12.5382 1.00009L12.5384 1.00009C14.0617 1.00836 15.5423 1.49559 16.766 2.39046C17.9896 3.28531 18.8927 4.54115 19.3457 5.97563C19.4022 6.15435 19.5538 6.2865 19.7386 6.31796C20.8056 6.49969 21.7676 7.06037 22.4442 7.893C23.1207 8.72549 23.4657 9.77269 23.4156 10.8377C23.3655 11.9028 22.9236 12.9141 22.1716 13.6812C21.4238 14.444 20.4203 14.913 19.3477 15H4.98013C4.07323 14.9088 3.2332 14.4896 2.62125 13.824C2.00433 13.153 1.66333 12.2798 1.66333 11.375C1.66333 10.4703 2.00433 9.59706 2.62125 8.92606C3.2383 8.25492 4.08726 7.83425 5.00283 7.74784C5.24049 7.72541 5.42915 7.5381 5.45329 7.30061C5.62921 5.56956 6.45146 3.96429 7.76114 2.7988Z"/>
      <rect x="5.50049" y="11.5898" width="14" height="7.41176" fill="#EFF2F5"/>
      <path d="M6.32397 18.1756L8.00524 13.2344H9.45575L11.1453 18.1756H9.85958L9.15905 15.6746C9.08213 15.4271 9.00796 15.1644 8.93653 14.8865C8.8651 14.6086 8.79368 14.3408 8.72225 14.0832H8.68929C8.62885 14.3408 8.56292 14.6086 8.49149 14.8865C8.42006 15.1644 8.34589 15.4271 8.26897 15.6746L7.5602 18.1756H6.32397ZM7.42834 17.0009V16.1294H10.0162V17.0009H7.42834Z" fill="#061023"/>
      <path d="M11.7324 18.1756V13.2344H13.5785C13.9741 13.2344 14.3312 13.2849 14.6499 13.3859C14.9741 13.4819 15.2323 13.6461 15.4246 13.8785C15.6169 14.111 15.713 14.4267 15.713 14.8259C15.713 15.2098 15.6169 15.5256 15.4246 15.7732C15.2323 16.0207 14.9768 16.2051 14.6581 16.3264C14.3395 16.4426 13.9906 16.5007 13.6115 16.5007H12.9439V18.1756H11.7324ZM12.9439 15.614H13.5373C13.8724 15.614 14.1197 15.5458 14.279 15.4094C14.4439 15.273 14.5263 15.0785 14.5263 14.8259C14.5263 14.5631 14.4384 14.3813 14.2625 14.2802C14.0867 14.1741 13.834 14.1211 13.5043 14.1211H12.9439V15.614Z" fill="#061023"/>
      <path d="M16.6419 18.1756V13.2344H17.8534V18.1756H16.6419Z" fill="#061023"/>
      </svg>
      `,
    },
  ];

  validations: ValidationsConfig = {
    jobTitle: {
      presence: { message: 'Please enter your job title' },
    },
    businessType: {
      presence: { message: 'Please select your business type' },
    },
    containersPerMonth: {
      presence: { message: 'Please select your container number for tracking per month' },
    },
    referralSource: {
      presence: { message: 'Please select how you heard about Terminal49' },
    },
    customerNeeds: {
      presence: { message: 'Please select how do you plan to use Terminal49' },
    },
  };

  constructor(owner: unknown, args: OnboardingUserInfoFormSignature['Args']) {
    super(owner, args);

    this.jobTitle = this.user.jobTitle || '';
    this.containersPerMonth = this.account.monthlyContainerVolume;
    this.customerNeeds = this.user.customerNeeds || [];
    this.customerNeedsOther = this.user.customerNeedsOther;
    if (this.referralSources.includes(this.user.referralSource)) {
      this.referralSource = this.user.referralSource;
    } else if (this.user.referralSource) {
      this.referralSource = 'Other';
      this.referralSourceOther = this.user.referralSource;
    }

    this.businessType = this.businessTypes.find(({ id }) => id === this.account.companyType);
  }

  @action
  validateAttr(attr: string) {
    this.validate({ only: [attr] });
  }

  @action
  toggleCustomerNeed(need: string) {
    if (this.customerNeeds.includes(need)) {
      this.customerNeeds = this.customerNeeds.filter((n) => n !== need);
    } else {
      this.customerNeeds = [...this.customerNeeds, need];
    }
  }

  @action
  updateReferralSourceOther(e: Event) {
    this.referralSourceOther = (e.target as HTMLInputElement).value;
  }

  @action
  updateCustomerNeedsOther(e: InputEvent) {
    this.customerNeedsOther = (e.target as HTMLInputElement).value;
  }

  get user() {
    return this.session.currentUser;
  }

  get account() {
    return this.user.primaryAccount;
  }

  submitFormTask = task(async (e: Event) => {
    e.preventDefault();

    this.serverErrors = [];
    this.formSubmitted = true;

    if (this.validate()) {
      const referralSource =
        this.referralSource === 'Other' ? this.referralSourceOther || 'Other' : this.referralSource;

      try {
        this.user.jobTitle = this.jobTitle;
        this.user.referralSource = referralSource;
        this.user.customerNeeds = this.customerNeeds;
        this.user.customerNeedsOther = this.customerNeedsOther;
        await this.user.save();

        this.account.companyType = this.businessType?.id || '';
        this.account.monthlyContainerVolume = this.containersPerMonth;
        await this.account.save();
        await this.onboarding.completeTaskUserInfo();

        this.notify.success('Your information has been saved.');
        this.args.closeModal?.();
      } catch (e: any) {
        console.error({ e });
        const errors: string[] =
          e.errors?.map((err: any) => {
            const message = err.detail;
            return message?.length > 300 ? message.slice(0, 300).concat('...') : message;
          }) || [];
        if (errors.length > 0) {
          this.serverErrors = errors;
        } else {
          this.serverErrors = ['Something went wrong. Please try again.'];
        }
      }
    }
  });
}

interface OnboardingUserInfoForm extends ValidatedObject, Component<OnboardingUserInfoFormSignature> {}

export default OnboardingUserInfoForm;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::UserInfoForm': typeof OnboardingUserInfoForm;
  }
}
