import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'tnt-ui/models/location';
import type ShipmentModel from 'tnt-ui/models/shipment';
import type TerminalModel from 'tnt-ui/models/terminal';
import type VesselModel from 'tnt-ui/models/vessel';
import { matchCargoShipmentEventToTransportEvent } from 'tnt-ui/utils/event-name-mappings';
import type RawEventModel from './raw-event';

export type TransportEventName =
  | 'container.transport.vessel_loaded'
  | 'container.transport.vessel_departed'
  | 'container.transport.vessel_arrived'
  | 'container.transport.vessel_berthed'
  | 'container.transport.vessel_discharged'
  | 'container.transport.empty_out'
  | 'container.transport.full_in'
  | 'container.transport.full_out'
  | 'container.transport.empty_in'
  | 'container.transport.test_empty_in'
  | 'container.transport.rail_departed'
  | 'container.transport.rail_arrived'
  | 'container.transport.rail_loaded'
  | 'container.transport.rail_unloaded'
  | 'container.transport.estimated.rail_unloaded'
  | 'container.transport.rail_interchange_received'
  | 'container.transport.rail_interchange_delivered'
  | 'container.transport.transshipment_arrived'
  | 'container.transport.transshipment_discharged'
  | 'container.transport.transshipment_loaded'
  | 'container.transport.transshipment_departed'
  | 'container.transport.feeder_arrived'
  | 'container.transport.feeder_discharged'
  | 'container.transport.feeder_loaded'
  | 'container.transport.feeder_departed'
  | 'container.transport.delivered'
  | 'container.pickup_lfd.changed'
  | 'container.transport.estimated.arrived_at_inland_destination'
  | 'container.transport.arrived_at_inland_destination'
  | 'container.transport.available';
export type ExtendedTransportEventName = TransportEventName | 'raw';

export default class TransportEventModel extends Model {
  @belongsTo('shipment', { async: false }) declare shipment: ShipmentModel;
  @belongsTo('vessel', { async: false }) declare vessel: VesselModel;
  @belongsTo('location', { async: false, polymorphic: true }) declare location: LocationModel;
  @belongsTo('terminal', { async: false }) declare terminal: TerminalModel;
  @belongsTo('container', { async: false }) declare container: any;
  @belongsTo('raw-event', { async: true }) declare sourceEvent: RawEventModel;
  @belongsTo('transport-event', { async: false, inverse: null }) declare previousVersion: TransportEventModel;

  @attr('string') declare event: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare voyageNumber: string;
  @attr('string') declare timestamp: string;
  @attr('string') declare locationLocode: string;
  @attr('string') declare timezone: string;
  @attr('string') declare dataSource: string;
  @attr('date') declare invalidatedAt: Date;
  @attr('string') declare invalidationReason: string;

  get humanizedEventName(): string {
    return matchCargoShipmentEventToTransportEvent(this.event).name ?? this.event;
  }

  get locationName(): string {
    try {
      return this.location?.name;
    } catch (e) {
      // for some transport events, the location is not loaded and will throw an error
      return '';
    }
  }

  get isTransportEvent(): boolean {
    return true;
  }
}
