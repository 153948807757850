import Service from '@ember/service';
import { debug } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import posthog from 'posthog-js';

export default class PostHogFeaturesService extends Service {
  @tracked featureOverrides: { [key: string]: string | boolean } = {};
  @tracked triggerChange: number | undefined = undefined;

  triggerToChange() {
    this.triggerChange = Math.random();
  }

  isPosthogFeatureEnabled(key: string) {
    const { featureOverrides } = this;
    if (key in featureOverrides) {
      const value = featureOverrides[key];
      debug(`Using overriden feature: "${key}"=${value}`);
      return value;
    } else {
      try {
        const value = posthog?.getFeatureFlag(key);
        debug(`Using feature defined in posthog: "${key}"=${value}`);
        return value;
      } catch (e) {
        return false;
      }
    }
  }

  async refresh() {
    try {
      await posthog.reloadFeatureFlags();
      debug('PostHog feature flags refreshed successfully.');
    } catch (error) {
      console.error('Error refreshing PostHog feature flags:', error);
    }
  }

  setPosthogFeature(key: string, value: string | boolean) {
    const { featureOverrides } = this;

    featureOverrides[key] = value;

    this.featureOverrides = featureOverrides;
  }
}
