import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'tnt-ui/services/feature-flags';
import type MetricsService from 'tnt-ui/services/metrics';
import type ModalsService from 'tnt-ui/services/modals';
import type PostHogFeaturesService from 'tnt-ui/services/posthog-features';
import type SessionService from 'tnt-ui/services/session';

export default class OwnerScopeContextService extends Service {
  @service declare session: SessionService;
  @service declare modals: ModalsService;
  @service declare metrics: MetricsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare posthogFeatures: PostHogFeaturesService;

  @tracked ownerId = '';

  get currentUser() {
    return this.session.currentUser;
  }

  get scopeFilters() {
    return [
      {
        id: '',
        name: 'All Containers',
      },
      {
        id: this.session.currentUser.id,
        name: 'My Containers',
      },
    ];
  }

  get currentScope() {
    return this.scopeFilters.find(({ id }) => isEqual(id, this.ownerId));
  }

  @action
  async initialScope() {
    if (this.featureFlags.isEnabled('myContainers') || this.posthogFeatures.isPosthogFeatureEnabled('my-containers')) {
      try {
        const userCargoMapping = await this.currentUser.userCargoMapping;
        this.ownerId = isEqual(userCargoMapping?.containersDashboardFilter, 'my') ? this.currentUser.id : '';
      } catch {
        return;
      }
    }
  }

  @action
  async addScope({ id }: any) {
    try {
      const userCargoMapping = await this.currentUser.get('userCargoMapping');

      if (userCargoMapping) {
        if (userCargoMapping.get('isNew')) {
          this.modals.open('setup-my-containers');
          this.trackEvent({
            event: 'Early Access Modal Opened',
            feature_flag_key: 'my-containers',
          });
        } else {
          const myContainersScope = isEqual(id, this.currentUser.id) ? 'my' : 'all';
          userCargoMapping.set('containersDashboardFilter', myContainersScope);
          await userCargoMapping.save();
          this.ownerId = id;
          this.metrics.context.myContainersScope = myContainersScope;
        }
      }
    } catch {
      this.modals.open('setup-my-containers');
      this.trackEvent({
        event: 'Early Access Modal Opened',
        feature_flag_key: 'my-containers',
      });
    }
  }

  @action
  trackEvent(attrs: Record<string, any>) {
    this.metrics.trackEvent(Object.assign({}, attrs));
  }
}
