/* import __COLOCATED_TEMPLATE__ from './setup-my-containers.hbs'; */
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type MetricsService from 'ember-metrics/services/metrics';
import type PostHogFeaturesService from 'tnt-ui/services/posthog-features';

export interface SetupMyContainersSignature {
  Args: {
    callback?: () => void;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class SetupMyContainers extends Component<SetupMyContainersSignature> {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare posthogFeatures: PostHogFeaturesService;

  @tracked optionNo = 1;
  @tracked isRunning = false;

  constructor(owner: unknown, args: SetupMyContainersSignature['Args']) {
    super(owner, args);
    this.preloadImages();
  }

  get options() {
    return [
      {
        no: 1,
        icon: 'fa-filter',
        title: 'Dashboard & Daily Digest Filtering:',
        description:
          'Quickly filter containers by customers, shipment tags, and POD. Get a personalized daily email digest',
      },
      {
        no: 2,
        icon: 'fa-sliders-simple',
        title: 'Toggle Between Views:',
        description: 'Easily switch between My Containers and All Containers',
      },
      {
        no: 3,
        icon: 'fa-gear',
        title: 'Default Preferences:',
        description: 'Set default filters so your dashboard always highlights your key containers.',
      },
      {
        no: 4,
        icon: 'fa-container-storage',
        title: 'Add Specific Containers:',
        description: 'Individually select containers to include in your personalized view.',
      },
    ];
  }

  get imageURL() {
    return `/assets/images/my-containers/image${this.optionNo}.png`;
  }

  get myContainersFeatureFlag() {
    return this.store
      .peekAll('feature-flag')
      .find(({ key, earlyAccess }) => isEqual(key, 'my-containers') && earlyAccess);
  }

  @action
  onChangeOption(optionNo: number) {
    this.optionNo = optionNo;

    this.trackEvent({
      event: 'Early Access Modal Option Clicked',
      option_no: optionNo,
    });
  }

  onAddAccountTask = task(async () => {
    if (!this.myContainersFeatureFlag!.accountIncluded) {
      await this.myContainersFeatureFlag!.addAccount();
      await this.posthogFeatures.refresh();
    }
    await this.router.transitionTo('settings.my-containers');
    this.args.callback?.();
  });

  trackEvent(attrs: {}) {
    this.metrics.trackEvent({
      ...attrs,
      feature_flag_key: 'my-containers',
    });
  }

  private preloadImages() {
    const imagePaths = [
      '/assets/images/my-containers/image1.png',
      '/assets/images/my-containers/image2.png',
      '/assets/images/my-containers/image3.png',
      '/assets/images/my-containers/image4.png',
    ];

    imagePaths.forEach((path) => {
      const img = new Image();
      img.src = path;
    });
  }
}
