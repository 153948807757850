/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import type MetricsService from 'tnt-ui/services/metrics';
import type SessionService from 'tnt-ui/services/session';

export interface RailUpsellSignature {
  Element: HTMLDivElement;
  Args: {
    source?: string;
  };
}

export default class RailUpsell extends Component<RailUpsellSignature> {
  @service declare session: SessionService;
  @service declare metrics: MetricsService;

  get variantToShow() {
    const currentUser = this.session.currentUser;
    const account = currentUser?.currentAccount;
    const billingAccount = account?.billingAccount;

    const endDate = get(account, `flagDetails.railData.endDate`);
    const railDataEnabled = get(account, `flags.railData`);

    if (billingAccount?.get('isTrialActive')) {
      return 'trial-access';
    } else if (endDate && railDataEnabled) {
      return 'flagged-access';
    } else {
      return 'non-access';
    }
  }

  get percentageTrialComplete() {
    const currentUser = this.session.currentUser;
    const account = currentUser?.currentAccount;

    const endDate = get(account, `flagDetails.railData.endDate`) as string;
    const startDate = get(account, `flagDetails.railData.startDate`) as string;

    if (endDate && startDate) {
      const endDateObj = new Date(endDate);
      const startDateObj = new Date(startDate);
      const today = new Date();
      const timeDiff = endDateObj.getTime() - startDateObj.getTime();
      const timeDiffToday = today.getTime() - startDateObj.getTime();
      const percentageComplete = (timeDiffToday / timeDiff) * 100;

      return percentageComplete;
    } else {
      return 0;
    }
  }

  get trialDaysLeft() {
    const currentUser = this.session.currentUser;
    const account = currentUser?.currentAccount;

    const endDate = get(account, `flagDetails.railData.endDate`) as string;

    if (endDate) {
      const endDateObj = new Date(endDate);
      const today = new Date();
      const timeDiff = endDateObj.getTime() - today.getTime();
      const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return daysLeft;
    } else {
      return 0;
    }
  }

  @action
  onShow() {
    this.trackEvent('upsell_badge_shown');
  }

  @action
  onInterestedClick() {
    this.trackEvent('upsell_interested_clicked');
  }

  @action
  onLearnMoreClicked() {
    this.trackEvent('upsell_learn_more_clicked');
  }

  @action
  trackEvent(event = '') {
    const source = this.args.source;

    this.metrics.trackEvent({
      event,
      source,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RailUpsell: typeof RailUpsell;
  }
}
