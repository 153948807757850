/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

interface Args {
  dataSource: string;
  dataProviderName: string;
  dataProviderCode: string;
  isTopLevelEvent: boolean;
}

export default class CargoSourceTagComponent extends Component<Args> {
  get sourceTypeString(): string | undefined {
    const mapping: Record<string, string> = {
      shipping_line: 'SSL',
      rail: 'RAIL',
      terminal: 'TERM',
      t49_operations_team: 'EDIT',
      ais: 'AIS',
    };
    return mapping[this.args.dataSource];
  }

  get colorClass(): string | undefined {
    const mapping: Record<string, string> = {
      shipping_line: 'tw-bg-cyan-50 tw-text-cyan-600 tw-border-cyan-600',
      rail: 'tw-bg-stone-50 tw-text-stone-600 tw-border-stone-600',
      terminal: 'tw-bg-lime-50 tw-text-lime-600 tw-border-lime-600',
      t49_operations_team: 'tw-bg-violet-50 tw-text-violet-600 tw-border-violet-600',
      ais: 'tw-bg-fuchsia-50 tw-text-fuchsia-600 tw-border-fuchsia-600',
    };
    return mapping[this.args.dataSource];
  }

  get fullSourceString(): string | undefined {
    if (this.args.isTopLevelEvent || ['ais', 't49_operations_team'].includes(this.args.dataSource)) {
      return this.sourceTypeString;
    } else {
      return `${this.sourceTypeString}:${this.args.dataProviderCode}`;
    }
  }

  get tooltipContent(): string | undefined {
    const humanizedDataSource = this.args.dataSource?.split('_').join(' ');
    if (this.args.dataSource === 'ais') {
      return 'Data sourced from AIS.';
    } else if (!this.args.dataProviderName || !this.args.dataProviderCode) {
      // This is for when there is no sourceEvent on a transport event
      // which should no longer happen, but it still does sometimes
      return `Data sourced via ${humanizedDataSource}.`;
    } else {
      const dataProviderName = this.args.dataProviderName;
      const dataProviderCode = this.args.dataProviderCode;
      return `Data sourced from ${dataProviderName} (${dataProviderCode}), a ${humanizedDataSource} provider.`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cargo::SourceTag': typeof CargoSourceTagComponent;
  }
}
